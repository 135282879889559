export interface SportsMenuCategoryNode {
  id: number;
  name: string;
  slug: string;
  flag: string;
  tournaments: SportsMenuTournamentNode[];
  eventsCount: number;
}

export interface SportsMenuTournamentNode {
  id: number;
  name: string;
  slug: string;
  flag: number;
  eventsCount: number;
}

export class ITournamentMenuItem {
  tournamentId: number;
  tournamentName: string;
  categoryId: number;
  categoryName: string;
  categoryFlag: string;
  sportId: number;
  sportName: string;
}

export class IMenu {
  name: string;
  isOpen?: boolean;
  description?: string;

  items: Array<IMenuItem>;

  constructor(config: Partial<IMenu>) {
    Object.assign(this, config);
  }
}

/** @deprecated **/
export interface ICmsMenuLink {
  orderId?: number;
  isExternal?: boolean;
  text: string;
  description?: string;
  url?: string;
  //icon?: string;
  subMenu?: IMenu;
  menuLinkId?: number;
  selected?: boolean;

  id?: number;
  name?: string;
  slug?: string;
  flag?: string;
  oddsCount?: number;
  eventsCount?: number;
  tournamentsCount?: number;
  categories?: SportsMenuCategoryNode[];
}

// MENU v2
export class IMenuItem {
  badge?: string;
  disabled?: boolean;
  expanded?: boolean;
  fragment?: string;
  icon?: string;
  id?: number;
  items?: IMenuItem[];
  label?: string;
  order?: number;
  routerLink?: any;
  target?: string;
  title?: string;
  url?: string;
  separator?: boolean;
  slug?: string;
  queryParams?: {
    [k: string]: any;
  };
  command?: (event?: any) => void;

  constructor(config: Partial<IMenuItem>) {
    Object.assign(this, config);
  }
}

export interface IMenuStatus {
  side?: boolean,
  section?: boolean,
  main?: boolean
}
